$(document).ready(function(){ 

	$('.banner').animate({
		'margin-top':'0',
		'opacity': '1'
	}, 2000, function(){
		$('.other').animate({
			'top':'230px'
		}, 1500);
		$('.title-p').fadeIn();
	});

	$('.title-h1').animate({
		'top':'0'
	}, 2000);

	// 滚动
	jQuery(".slideBox").slide({mainCell:".bd ul",autoPlay:true});

	// 音频播放
	var audio = document.getElementById('audio');
	function themeMusic (musM,numberM,urlM) {
		$(musM).on("click",function(){
			var random = Math.floor(Math.random() * numberM);
			var url = '/images/music/' + urlM + '/music_' + random + '.mp3';
			$(audio).prop("src",url);
			audio.play();

			$(this).removeClass('play').addClass('voice').parent("li").siblings('li').find('a').attr('class','play').parent('li').parent('ul').parent('.tape-box').parent('.works-box').parent('.modular-wrap').siblings('.modular-wrap').find('a').attr('class','play');
			$(this).parent('li').addClass('scale-big').siblings('li').removeClass('scale-big');
		});
		audio.addEventListener("ended", function (){  
			$('.checkpoint-wrap ul li a').removeClass('voice').addClass('play');
			$('.checkpoint-wrap ul li').removeClass('scale-big');
		});
	};
	var theme01 = themeMusic('.mus-01 a', 20, 'renzhechun');
	var theme02 = themeMusic('.mus-02 a', 2, 'sanguoguanka');
	var theme03 = themeMusic('.mus-03 a', 4, 'xingji');
	var theme04 = themeMusic('.mus-04 a', 4, 'yiluxiangxi');
	var theme05 = themeMusic('.mus-05 a', 9, 'huluwa');
	var theme06 = themeMusic('.mus-06 a', 2, 'yugongyishanwanmei');
	var theme07 = themeMusic('.mus-07 a', 22, 'jiejibaolei');
	var theme08 = themeMusic('.mus-08 a', 2, 'moshou');
	var theme09 = themeMusic('.mus-09 a', 9, 'kexuetaohuadao');
	var theme10 = themeMusic('.mus-10 a', 6, 'kongbushijie');

	// 视频播放
	var video = document.getElementById("video-play");
	$('.picList li').on("click",function(){
		$("#video-play").remove();
		video.src = $(this).attr('media-src');
		var html='<embed id="video-play" width="100%" height="97%" name="plugin" src=" '+video.src+' " type="application/x-shockwave-flash" />';
		$(".video-div").append(html);
		$('.video-div span').fadeOut();
		$('.video-fixed').fadeIn();
		$('.checkpoint-wrap ul li a').removeClass('voice').addClass('play');
		$('.checkpoint-wrap ul li').removeClass('scale-big');
	});

	
	$('.video-li').on("click",function(){
		$('.video-fixed').fadeIn();
		$('#video-play').attr("src","images/ppening_animation.mp4");
		video.play();
	})

	$('.video-fixed span').on("click",function(){
		$('.video-fixed').fadeOut();
		$('#video-play').attr("src","");
	});

	$('.video-div span').on("click",function(){
		$(this).fadeOut();
		change = false;
	});	

	// 视频开始暂停
	var change = true;
	$('#video-play').on("click",function(){
		if (change) {
			video.play();
			change = false;
		} else {
			video.pause();
			change = true;
		}
	});	

	// 更多合作媒体
	var change2 = true;
	$('.coop-box').click(function() {
		if (change2) {
			$('.coop-all').fadeIn();
			$('.coop-box').css({'background':'#939393'});
			$('.coop-box img').addClass('o-rota');
			change2 = false;
		} else {
			$('.coop-all').fadeOut();
			$('.coop-box').css({'background':'#b5b5b5'});
			$('.coop-box img').removeClass('o-rota');
			change2 = true;
		}
	});
	$('.coop-box').mouseleave(function() {
		$('.coop-all').fadeOut();
		$('.coop-box').css({'background':'#b5b5b5'});
		$('.coop-box img').removeClass('o-rota');
		change2 = true;
	});

	// 简介
	$('.synopsis-btn').click(function(){
		$('.synopsis-wrap').fadeIn();
	});

	$('.close').click(function(){
		$('.synopsis-wrap').fadeOut();
	});

	// 返回顶部
	$('.scrollTop').click(function(){
		$('html, body').stop().animate({
			'scrollTop' : '0'
		},1000);
	});

	// hover微信
	$('.weix').hover(function(){
		$('.other img').fadeIn();
	}, function(){
		$('.other img').addClass('scale-small').fadeOut();
		setTimeout(function() {$('.other img').removeClass('scale-small')},500);
	});

	// // ios链接  更新时间2017-11-7
	// $('.ios-btn').click(function(){
	// 	location.href = 'https://itunes.apple.com/cn/app/%E6%84%9A%E5%85%AC%E7%A7%BB%E5%B1%B13%E6%99%BA%E5%8F%9F%E7%9A%84%E5%8F%8D%E5%87%BB-%E4%BF%AE%E4%BB%99%E7%89%88/id1159702996?l=zh&ls=1&mt=8';
	// });

	// // 安卓链接 更新时间2017-11-7
	// $('.android-btn').click(function(){
	// 	location.href = 'http://download2.qcplay.com/son/Client/son3_qc_v40_1.0.171104.01.apk';
	// });

	// // taptap链接
	// $('.taptap-btn').click(function(){
	// 	location.href = 'http://l.taptap.com/aetE3kaE';
	// });

	// 安卓下载包替换
	// var time_1 = "2017/1/21 00:00:00";
	// var sys_second_1 = (new Date(time_1).getTime()-new Date().getTime())/1000;
	// $('.android-btn').click(function() {
	// 	if (sys_second_1 < 0) {
	// 		location.href = 'http://download2.qcplay.com/son/Client/son3_qc_v28_1.0.170426.01.apk';
	// 	} else {
	// 		location.href = 'https://download2.qcplay.com/son/Client/yugong3.apk';
	// 	}
	// });	
}); 